import React from 'react';

const styles = {
	extWidth: {
		width: '150px',
	},
	sixteen: {
		width: '16%',
	},
	socials: {
		maxWidth: '70px',
		padding: '0px',
	},
};

export const Guests = () => {
	return (
		<div>
			<div className='container'>
				<div>
					<img
						alt='voice actor round table'
						style={{
							width: '360px',
							margin: '0 auto',
							display: 'block',
							paddingTop: '50px',
						}}
						src={require('./images/VoiceActorRoundTableTitle.png')}
					/>
				</div>
				<div
					className='row'
					style={{ paddingTop: '50px', maxWidth: '1200px', margin: '0 auto' }}
				>
					<div className='col-xs-12 col-md-6 col-lg-6'>
						<div className='card'>
							<img
								className='img-responsive'
								src={require('./images/TPS-_0004_Guest-DavidHayter.png')}
								alt='David Hayter - Metal gear Solid, X-men, X-men 2 , Warrior Nun, Watchmen, Netflix'
							/>
							<div className='divTable' style={styles.sections}>
								<div className='divTableBody'>
									<div className='divTableRow'>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
										<div className='divTableCell card2 hover02'>
											<a
												style={styles.sixteen}
												href='https://www.imdb.com/name/nm0371684/'
												target='_blank'
												rel='noreferrer'
											>
												<figure>
													<img
														alt='social imdb'
														src={require('./images/Social-Icons-_0006_IMDB.webp')}
														style={styles.socials}
													/>
												</figure>
											</a>
										</div>
										<div className='divTableCell card2 hover02'>
											<a
												style={styles.sixteen}
												href='https://twitter.com/DavidBHayter'
												target='_blank'
												rel='noreferrer'
											>
												<figure>
													<img
														alt='social twitter'
														src={require('./images/Social-Icons-_0002_Twitter.webp')}
														style={styles.socials}
													/>
												</figure>
											</a>
										</div>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='col-xs-12 col-md-6 col-lg-6'>
						<div className='card'>
							<img
								className='img-responsive'
								src={require('./images/TPS-_0000_Guest-JenniferHale.png')}
								alt='Jennifer Hale - Mass Effect, Ratchet & Clank, The Witcher, Star Wars'
							/>
							<div className='divTable' style={styles.sections}>
								<div className='divTableBody'>
									<div className='divTableRow'>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
										<div className='divTableCell card2 hover02'>
											<a
												style={styles.sixteen}
												href='https://www.imdb.com/name/nm0371684/'
												target='_blank'
												rel='noreferrer'
											>
												<figure>
													<img
														alt='IMDB'
														src={require('./images/Social-Icons-_0006_IMDB.webp')}
														style={styles.socials}
													/>
												</figure>
											</a>
										</div>
										<div className='divTableCell card2 hover02'>
											<a
												style={styles.sixteen}
												href='https://twitter.com/DavidBHayter'
												target='_blank'
												rel='noreferrer'
											>
												<figure>
													<img
														alt='Twitter'
														src={require('./images/Social-Icons-_0002_Twitter.webp')}
														style={styles.socials}
													/>
												</figure>
											</a>
										</div>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='container'>
				<div className='row' style={{ maxWidth: '1200px', margin: '0 auto' }}>
					<div className='col-xs-12 col-md-4 col-lg-4'>
						<div className='card'>
							<img
								className='img-responsive'
								src={require('./images/TPS-_0001_Guest-JeffLeach.png')}
								alt='Jeff Leach - Call of Duty, Call of Duty Ghosts, Battlestar Galactica Deadlock'
							/>
							<div
								className='divTable'
								style={{
									alignContent: 'center',
									textAlign: 'center',
									margin: '0 auto',
									width: '100%',
									maxWidth: '600px',
									marginTop: '-60px',
								}}
							>
								<div className='divTableBody'>
									<div className='divTableRow'>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
										<div className='divTableCell card2 hover02'>
											<a
												style={styles.sixteen}
												href='https://www.imdb.com/name/nm0371684/'
												target='_blank'
												rel='noreferrer'
											>
												<figure>
													<img
														alt='IMDB'
														src={require('./images/Social-Icons-_0006_IMDB.webp')}
														style={styles.socials}
													/>
												</figure>
											</a>
										</div>
										<div className='divTableCell card2 hover02'>
											<a
												style={styles.sixteen}
												href='https://twitter.com/DavidBHayter'
												target='_blank'
												rel='noreferrer'
											>
												<figure>
													<img
														alt='twitter'
														src={require('./images/Social-Icons-_0002_Twitter.webp')}
														style={styles.socials}
													/>
												</figure>
											</a>
										</div>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='col-xs-12 col-md-4 col-lg-4'>
						<div className='card'>
							<img
								className='img-responsive'
								src={require('./images/TPS-_0002_Guest-SandraSaad.png')}
								alt='Sandra Saad - Marvels Avengers, Deathloop'
							/>
							<div
								className='divTable'
								style={{
									alignContent: 'center',
									textAlign: 'center',
									margin: '0 auto',
									width: '100%',
									maxWidth: '600px',
									marginTop: '-60px',
								}}
							>
								<div className='divTableBody'>
									<div className='divTableRow'>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
										<div className='divTableCell card2 hover02'>
											<a
												style={styles.sixteen}
												href='https://www.imdb.com/name/nm0371684/'
												target='_blank'
												rel='noreferrer'
											>
												<figure>
													<img
														alt='IMDB'
														src={require('./images/Social-Icons-_0006_IMDB.webp')}
														style={styles.socials}
													/>
												</figure>
											</a>
										</div>
										<div className='divTableCell card2 hover02'>
											<a
												style={styles.sixteen}
												href='https://twitter.com/DavidBHayter'
												target='_blank'
												rel='noreferrer'
											>
												<figure>
													<img
														alt='Twitter'
														src={require('./images/Social-Icons-_0002_Twitter.webp')}
														style={styles.socials}
													/>
												</figure>
											</a>
										</div>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='col-xs-12 col-md-4 col-lg-4'>
						<div className='card'>
							<img
								className='img-responsive'
								src={require('./images/TPS-_0003_Guest-ErikBraa.png')}
								alt='Erik Braa - League Of Legends, World of Warcraft, StarCraft 2, Unicorn Dick: Investigations'
							/>
							<div
								className='divTable'
								style={{
									alignContent: 'center',
									textAlign: 'center',
									margin: '0 auto',
									width: '100%',
									maxWidth: '600px',
									marginTop: '-60px',
								}}
							>
								<div className='divTableBody'>
									<div className='divTableRow'>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
										<div className='divTableCell card2 hover02'>
											<a
												style={styles.sixteen}
												href='https://www.imdb.com/name/nm0371684/'
												target='_blank'
												rel='noreferrer'
											>
												<figure>
													<img
														alt='IMDB'
														src={require('./images/Social-Icons-_0006_IMDB.webp')}
														style={styles.socials}
													/>
												</figure>
											</a>
										</div>
										<div className='divTableCell card2 hover02'>
											<a
												style={styles.sixteen}
												href='https://twitter.com/DavidBHayter'
												target='_blank'
												rel='noreferrer'
											>
												<figure>
													<img
														alt='twitter'
														src={require('./images/Social-Icons-_0002_Twitter.webp')}
														style={styles.socials}
													/>
												</figure>
											</a>
										</div>
										<div
											className='divTableCell card2'
											style={styles.extWidth}
										></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
