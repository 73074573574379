import React from 'react';
import Banner from './images/_0000_TPS-Banner-Logo.png'
import BG from './images/TPSUDI-Presents.png'
import './Header.css'

const styles = {
    bannerImg: {
      maxHeight: '50px',
      width: 'auto',
      margin: '0 auto',
      marginTop: '80px',
   }
  }

export const Header = () => {
	return (
		<div>
			<div className='jumbotron text-center tpsbanner bg-cover'>
				<div>
					<img
						alt='banner logo'
						src={Banner}
						style={styles.bannerImg}
					/>
				</div>
			</div>
			<div className='udibanner text-center bg-cover'>
					<div id='ImgZoom'>
						<a
							href='https://TwitchPixel.com/UDA'
							target='_blank'
							rel='noreferrer'
						>
							<img
								alt='Twitch Pixel Studios Home'
								className='scale-with-grid'
								style={{
									maxHeight: '250px',
									margin: '25px auto',
									padding: '25px 0px',
								}}
								src={BG}
							/>
						</a>
					</div>
			</div>
		</div>
	);
};
