import React from 'react';

export const Itinerary = () => {
	return (
		<div>
			<h2 style={{ color: 'white', marginTop: '25px', textAlign: 'center' }}>This event is now over. </h2><p style={{ color: 'white', textAlign: 'center' }}>To stay updated on all our Omnio-Pass Events follow <a href='https://twitter.com/TwitchPixelInc'>@TwitchPixelInc</a> &amp;  <a href='https://twitter.com/TwitchPixelAnim'>@TwitchPixelAnim</a> on Twitter</p>
		</div>
		/* <div>
			<div className='container'>
				<div className='row' style={{ padding: '25px', color: 'white' }}>
					<div className='col-sm-4' style={{ textAlign: 'right' }}>
						<h3>ITINERARY</h3>
					</div>
				</div>
				<div className='row' style={{ padding: '25px', color: 'white' }}>
					<div
						className='col-sm-4'
						style={{ borderRight: '5px solid purple', textAlign: 'right' }}
					>
						<h4>Pre-Show</h4>
					</div>
					<div
						className='col-sm-8'
						style={{
							display: 'block',
							height: '10px',
							backgroundColor: 'purple',
						}}
					></div>
				</div>
				<div className='row' style={{ padding: '25px', color: 'white' }}>
					<div
						className='col-sm-4'
						style={{ borderRight: '5px solid purple', textAlign: 'right' }}
					>
						<strong>Meet Twitch Pixel Studios &amp; Unicorn Dick</strong>
						<br />
						11:45 am PST | 1:45 pm CST | 2:45 pm EST
						<br />
						Saturday, March 21st.
						<br />
					</div>
					<div className='col-sm-8'>
						Introduction
						<br />
						<br />
						Who is Twitch Pixel Studios?.
						<br />
						Creator Discussion
						<br />
					</div>
				</div>
				<div className='row' style={{ padding: '25px', color: 'white' }}>
					<div
						className='col-sm-4'
						style={{ borderRight: '5px solid purple', textAlign: 'right' }}
					>
						<strong>Pre-Show Intermission &amp; Warm Up</strong>
						<br />
						12:00 pm PST | 2:00 pm CST | 3:00 pm EST
						<br />
						Saturday, March 21st.
						<br />
					</div>
					<div className='col-sm-8'>
						Intermission Video
						<br />
						Main Show Warm Up
						<br />
					</div>
				</div>
			</div>
			<div className='container'>
				<div className='row' style={{ padding: '25px', color: 'white' }}>
					<div
						className='col-sm-4'
						style={{ borderRight: '5px solid springgreen', textAlign: 'right' }}
					>
						<h4>Main Show</h4>
					</div>
					<div
						className='col-sm-8'
						style={{
							display: 'block',
							height: '10px',
							backgroundColor: 'springgreen',
						}}
					></div>
				</div>
				<div className='row' style={{ padding: '25px', color: 'white' }}>
					<div
						className='col-sm-4'
						style={{ borderRight: '5px solid springgreen', textAlign: 'right' }}
					>
						<strong>Introductions</strong>
						<br />
						12:05 pm PST | 2:05 pm CST | 3:05 pm EST
						<br />
						Saturday, March 21st.
						<br />
					</div>
					<div className='col-sm-8'>
						Introducing Erik Braa, Sandraa Saad, Jeff Leach, Jennifer Hale,
						&amp; David Hayter!
						<br />
						Infos' and intros' of our Awesome Guests!
					</div>
				</div>
				<div className='row' style={{ padding: '25px', color: 'white' }}>
					<div
						className='col-sm-4'
						style={{ borderRight: '5px solid springgreen', textAlign: 'right' }}
					>
						<strong>
							Main Show! Voice Actors Round Table - Acting &amp; Inspiration
						</strong>
						<br />
						12:10 pm PST | 2:10 pm CST | 3:10 pm EST
						<br />
						Saturday, March 21st.
						<br />
					</div>
					<div className='col-sm-8'>
						50 Mintute Round Table where our guests will take part in a
						discussion about thier experiences in the world of video games &amp;
						animation over their careers, their inspirations, and what aspiring
						individuals can do to find their way!
					</div>
				</div>
				<div className='row' style={{ padding: '25px', color: 'white' }}>
					<div
						className='col-sm-4'
						style={{ borderRight: '5px solid springgreen', textAlign: 'right' }}
					>
						<strong>Fan Q&amp;A</strong>
						<br />
						1:00 pm PST | 3:00 pm CST | 4:00 pm EST
						<br />
						Saturday, March 21st.
						<br />
					</div>
					<div className='col-sm-8'>
						25 Minute Fan Q&amp;A. Through our{' '}
						<strong>exclusive Discord channels</strong> our ThetaDrop Members
						&amp; our Unicorn Dick Investigations NFT Holders will be able to
						submit questions for our guests which we will select from and ask
						live with each actor!
					</div>
				</div>
				<div className='row' style={{ padding: '25px', color: 'white' }}>
					<div
						className='col-sm-4'
						style={{ borderRight: '5px solid springgreen', textAlign: 'right' }}
					>
						<strong>Wrap up</strong>
						<br />
						1:25 pm PST | 3:25 pm CST | 4:25 pm EST
						<br />
						Saturday, March 21st.
						<br />
					</div>
					<div className='col-sm-8'>
						Some final words from Twitch Pixel studios and our Guests.
					</div>
				</div>
			</div>
			<div className='container'>
				<div className='row' style={{ padding: '25px', color: 'white' }}>
					<div
						className='col-sm-4'
						style={{ borderRight: '5px solid deeppink', textAlign: 'right' }}
					>
						<h4>After Party Post-Show</h4>
					</div>
					<div
						className='col-sm-8'
						style={{
							display: 'block',
							height: '10px',
							backgroundColor: 'deeppink',
						}}
					><br/><p>Come hang out with Twitch Pixel Studios and friends as we
					ride the buzz, talk animation, video games, and all our
					inspirations!</p>
					</div>
				</div>
				<div className='row' style={{ padding: '25px', color: 'white' }}>
					<div className='col-sm-8'>
					</div>
				</div>
				<div className='row' style={{ padding: '25px', color: 'white' }}>
					<div
						className='col-sm-4'
						style={{ borderRight: '5px solid deeppink', textAlign: 'right' }}
					>
						<strong>Intermission Video</strong>
					</div>
					<div className='col-sm-8'>
						A Short Break Before our After-Party Event!
					</div>
				</div>
				<div className='row' style={{ padding: '25px', color: 'white' }}>
					<div
						className='col-sm-4'
						style={{ borderRight: '5px solid deeppink', textAlign: 'right' }}
					>
						<strong>Twitch Pixel Hangout</strong>
					</div>
					<div className='col-sm-8'>
						Hangout With Twitch Pixel Studios and a few of our Guests &amp;
						Friends, as we chat pop culture, and generally nerd out about our
						experiences and inpirations!
					</div>
				</div>
				<div className='row' style={{ padding: '25px', color: 'white' }}>
					<div
						className='col-sm-4'
						style={{ borderRight: '5px solid deeppink', textAlign: 'right' }}
					>
						<strong>Thank you - Wrap up!</strong>
					</div>
					<div className='col-sm-8'>
						A special thank you from the Twitch Pixel Team, and a final Wrap-Up
						for our dedicated viewers!
					</div>
				</div>
			</div>
		</div> */
	);
};
