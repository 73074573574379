import React from 'react';

const styles = {
	hRule: {
		borderTop:'1px solid grey',
	}
}

export const Passes = () => {

	return (
<div className="container" style={{color: 'white', marginTop: '25px'}}>
    <div className="row">
        <div className="col-xs-12" style={{textAlign:'center', maxWidth:'800px', marginTop:'25px', marginRight:'auto', marginBottom:'25px', marginLeft:'auto', display:'block', clear:'both', padding:'25px'}}>
            <h5>3 WAYS TO FULL </h5>
            <h3 style={{marginTop:'-10px'}}>COMMUNITY ACCESS!</h3>
            <p>Supporting gives you access to exclusive OmniPass ONLY Events as well as:<br/>
                Actor roundtables and Q&amp;A’s, Interviews, Behind the Scenes Videos, Production Diaries, LiveStreams, Gaming with the team, and so much more!<br/><br/>
                Support for a <strong>cup of coffee</strong> on Patreon, Snag one of our shows <strong>Collectible Digital Characters</strong>, Or get a limited <strong>LIFETIME Omni Pass</strong> to all our Community exclusive events!
            </p>
        </div>
        </div>
    <div className="row">
        <div className="col-md-4">
        <a href="https://flair.finance/campaigns/61ad2336f1c243bfa3d82dc4"><img src={require("./images/TPS-_0002_IMAGE-MINT-Citizen.png")} style={{width:'300px', margin:'0 auto', display:'block', marginBottom:'15px'}} alt='mint citizen'/></a>
        <ul style={{textAlign:'left', listStyleType:'none', backgroundColor:'#360044', marginLeft:'0px', marginRight:'2px', paddingTop:'20px', paddingBottom:'20px', paddingRight:'35px', fontSize:'12px'}}>
            <li style={{textAlign:'center', fontWeight:700, fontSize:'18px', backgroundColor:'purple', padding:'10px'}}>MINT Your Citizen!</li>
			<hr style={styles.hRule}/>
            <li style={{textAlign:'center', fontWeight:700, fontSize:'14px'}}>130 MATIC (Polygon)</li>
			<hr style={styles.hRule}/>
            <li>Join our Animated Universe!</li>
			<hr style={styles.hRule}/>
            <li>LIFETIME VIP Livestream Access </li>
			<hr style={styles.hRule}/>
            <li>Uniqie "Citizen" Top Tier Discord Role!</li>
			<hr style={styles.hRule}/>
            <li>Your NFTs Grow with our Animated Series!</li>
			<hr style={styles.hRule}/>
            <li>Take Part in our Animated Expanded Universe!</li>
			<hr style={styles.hRule}/>
            <li>Access to our Development Team Discord Channels</li>
			<hr style={styles.hRule}/>
            <li>Join in on our Uniqie Community Live Story telling events! Make cartoons with us!</li>
			<hr style={styles.hRule}/>
            <br/>
            <a href="https://flair.finance/campaigns/61ad2336f1c243bfa3d82dc4"><button type="button" className="btn btn-primary im" style={{padding:'15px', backgroundColor:'mediumseagreen', border:'none', width:'100%', textAlign:'center'}}> <strong>MINT NOW!</strong></button></a>
        </ul>
        </div>
        <div className="col-md-4">
        <a href="https://www.patreon.com/TwitchPixel"><img src={require("./images/TPS-_0001_IMAGE-SupportTwitchPixelPatreon.png")} style={{width:'300px', margin:'0 auto', display:'block', marginBottom:'15px'}} alt='Patreon'/></a>
        <ul style={{textAlign:'left', listStyleType:'none', backgroundColor:'#360044', marginLeft:'0px', marginRight:'2px', paddingTop:'20px', paddingBottom:'20px', paddingRight:'35px', fontSize:'12px'}}>
            <li style={{textAlign:'center', fontWeight:700, fontSize:'18px', backgroundColor:'purple', padding:'10px'}}>SUPPORT For a Cup of Coffee</li>
			<hr style={styles.hRule}/>
            <li style={{textAlign:'center', fontWeight:700, fontSize:'14px'}}>$4.99 / Month</li>
			<hr style={styles.hRule}/>
            <li>Specialized Patreon Supporter Discord Role!</li>
			<hr style={styles.hRule}/>
            <li>Access to BTS Discord Channels &amp; Live Streams!</li>
			<hr style={styles.hRule}/>
            <li>Developer Diary Video Updates!</li>
			<hr style={styles.hRule}/>
            <li>Patreon Only Digtial Rewards!</li>
			<hr style={styles.hRule}/>
            <br/>
            <a href="https://www.patreon.com/TwitchPixel"><button type="button" className="btn btn-primary im" style={{padding:'15px', backgroundColor:'mediumseagreen', border:'none', width:'100%', textAlign:'center'}}><strong>SUPPORT NOW!</strong></button></a>
        </ul>
        </div>
        <div className="col-md-4">
        <a href="https://twitchpixel.thetadrop.com/"><img src={require("./images/TPS-_0000_IMAGE-OniPass.png")} style={{width: '300px', margin: '0 auto', display: 'block', marginBottom: '15px'}} alt='omnipass'/></a>
        <ul style={{textAlign:'left', listStyleType:'none', backgroundColor:'#360044', marginLeft:'2px', marginRight:'0px', paddingTop:'20px', paddingBottom:'20px', paddingRight:'35px', fontSize:'12px'}}>
            <li style={{textAlign:'center', fontWeight:700, fontSize:'18px', backgroundColor:'purple', padding:'10px'}}>Life Time VIP OmniPass</li>
			<hr style={styles.hRule}/>
            <li style={{textAlign: 'center', fontWeight: 700, fontSize: '14px'}}>$60USD Lifetime Event Pass</li>
			<hr style={styles.hRule}/>
            <li>LIFETIME VIP Livestream Access (All Future OmniPass Events)</li>
			<hr style={styles.hRule}/>
            <li>Livestream VOD Download</li>
			<hr style={styles.hRule}/>
            <li>HD Digital Posters Pack:
                <ul style={{textAlign:'left', listStyleType:'none'}}>
                    <li>Unicorn Dick: Investigations</li>
                    <li>Lost Hope </li>
                    <li>Unity One </li>
                    <li>Beat Monsters</li>
                </ul>
            </li>
			<hr style={{borderTop:'1px solid grey'}}/>
            <li>OmniPass VIP Discord Role!</li>
			<hr style={{bordeTop:'1px solid grey'}}/>
            <li>Special Thank you video from the TwitchPixel team!</li>
			<hr style={{borderTop:'1px solid grey'}}/>
            <br/>
            <a href="https://twitchpixel.thetadrop.com/"><button type="button" className="btn btn-primary im" style={{padding:'15px', backgroundColor:'mediumseagreen', border:'none', width:'100%', textAlign:'center'}}><strong>BECOME A VIP!</strong></button></a>
        </ul>
        </div>
    </div>
</div>
	);
};