import React from 'react';
import { Guests } from './Guests';
import { Itinerary } from './Itinerary';
import { Passes } from './Passes';

const styles = {
	hRule: {
		borderTop:'1px solid grey',
	}
}

export const NotAuth = ({ authState, connectToThetaDrop }) => {

	return (
		<>
			{authState === false && <div style={{maxWidth: '800px', marginLeft: 'auto', marginRight:'auto', paddingTop:'30px'}}><h1 className='text-white text-center'>
We're Sorry!
			</h1>
			<p className='text-white text-center'>It appears you currently do not own one of our ThetaDrop NFT's <br/><strong> OR </strong><br/> Have yet to open one your packs! <br/>
Please hop on over to your collection on ThetaDrop and reveal one, or visit our marketplace on <a href="https://twitchpixel.thetadrop.com/" target="_blank" rel="noopener noreferrer">ThetaDrop</a> to buy one of your own!<br/><br/> If you are still having technical difficulties please <a href="https://bit.ly/TPSDiscord" target="_blank" rel="noopener noreferrer"> Join our Discord</a> and request assitance from our team!</p>
			</div>
			 }
			<div className='container my-5'>
				<div className='row justify-content-center'>
					<div className='text-white text-center'>
						<h3>Connect Your OmniPass for your exclusive content!</h3>
						<p>Login to your ThetaDrop to Connect your Omni Pass and get access to our exclusive event!</p>
						<button className='btn btn-success' onClick={connectToThetaDrop} style={{padding: '20px'}}>
							Connect ThetaDrop
						</button>
					</div>
					<div>
			<h2 style={{ color: 'white', marginTop: '25px', textAlign: 'center' }}>This event is now over. </h2><p style={{ color: 'white', textAlign: 'center' }}>To stay updated on all our Omnio-Pass Events follow <a href='https://twitter.com/TwitchPixelInc'>@TwitchPixelInc</a> &amp;  <a href='https://twitter.com/TwitchPixelAnim'>@TwitchPixelAnim</a> on Twitter</p>
		</div>
				</div>
				<br/>
				<hr style={styles.hRule}/>
				<div className='row justify-content-center'>
				<div className='text-white text-center' style={{paddingTop: '20px'}}>
				<h3>No OmniPass? No Problem!</h3>
				<p>Hop over to <a style={{fontWeight:700, color:'springgreen'}} href="https://twitchpixel.thetadrop.com/" target="_blank" rel="noopener noreferrer"><strong>ThetaDrop</strong></a> and snag your OmniPass!</p><br/>
				<div className='im'><a href="https://twitchpixel.thetadrop.com/" target="_blank" rel="noopener noreferrer"><img src={require("./images/GetYourTWPSOmnipassNow.jpg")} style={{maxWidth:'80%', margin:'0 auto', display:'block', marginBottom:'15px'}} alt='snag your omnipass'/><br/></a></div>
				</div>
				</div>
			</div>
			<Passes />
            <Guests />
            <Itinerary />
		</>
	);
};
