/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import './App.css';
import { Auth } from './components/Auth';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { NotAuth } from './components/NotAuth';
import { ThetaDropConnect } from '@thetalabs/theta-drop-connect';

const AppId = process.env.REACT_APP_AppId;
const redirectURL = process.env.REACT_APP_redirectURL;
const ThetaZillaId = process.env.REACT_APP_ThetaZillaId

function App() {
  const [userOwner, setUserOwner] = useState(false);
  const [userData, setUserData] = useState({})
	const [authState, setAuthState] = useState('');

	let thetaDrop = new ThetaDropConnect();

  useEffect(() => {
    finishConnectionViaRedirect()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let finishConnectionViaRedirect = async() => {
 try {
   const result = await thetaDrop.finishConnectViaRedirect()

   if(result) {
     const {snsId, oauth2Token} = result
     setAuthState({
      tpsId: snsId,
      authToken: oauth2Token,
    });
    refreshUser()
    checkOwner()
  }
} catch (err){
  console.log(err)
}
}


let refreshUser = async () => {
  try {

    const userData = await thetaDrop.fetchUser();

     setUserData({
       userData
     })
    } catch (err){
      console.log(err)
    }

	};

  let checkOwner = async() => {
    try {
      const filters = {
        content_id: ThetaZillaId
      }

      await thetaDrop.fetchUserNFTs(filters)

      const isOwner = await thetaDrop.checkUserIsOwner(filters)
      setUserOwner(isOwner)
      setAuthState(isOwner)
    } catch (err){
      console.log(err);
    }
  }

	let connectToThetaDrop = async () => {
    try {
      const { snsId, oauth2Token } = await thetaDrop.connectViaRedirect(
        AppId,
        redirectURL
        );

        setAuthState({
          tpsId: snsId,
          authToken: oauth2Token,
        });
        refreshUser();
      } catch (err){
        console.log(err)
      }
	};

	return (
		<div className='body'>
			<Header />
			{userOwner ? (
				<Auth />
			) : (
				<NotAuth authState={authState} connectToThetaDrop={connectToThetaDrop} />
			)}
			<Footer />
		</div>
	)
}
export default App; 