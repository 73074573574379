import React from 'react';

const styles = {
	sixteen: {
		width: '16%',
	},
};

export const Footer = () => {
	return (
		<div>
			<div className='col'>
				<div className='card4'>
					<img
						loading='lazy'
						className='img-responsive'
						style={{ maxHeight: '60px', display: 'block', margin: '0 auto', width: '100%' }}
						src={require('./images/SupportOn.png')}
						alt='Our Artists'
					/>
				</div>
			</div>
			<div
				className='row'
				style={{
					maxWidth: '1200px',
					margin: '0 auto',
					alignContent: 'center',
					textAlign: 'center',
				}}
			>
				<div className='col-xs-12 col-md-4 col-lg-4'>
					<div className='card hover02'>
						<a href='https://bit.ly/TPS-Patreon'>
							<figure>
								<img
									style={{ maxHeight: '60px' }}
									className='img-responsive'
									src={require('./images/TPS-_0002_PatreonBTN.png')}
									alt='Support Us on Patreon'
								/>
							</figure>
						</a>
					</div>
				</div>

				<div className='col-xs-12 col-md-4 col-lg-4'>
					<div className='card hover02'>
						<a href='https://bit.ly/TPSDiscord'>
							<figure>
								<img
									style={{ maxHeight: '60px' }}
									className='img-responsive'
									src={require('./images/TPS-_0001_DiscordBTN.png')}
									alt='Join our Discord'
								/>
							</figure>
						</a>
					</div>
				</div>

				<div className='col-xs-12 col-md-4 col-lg-4'>
					<div className='card hover02'>
						<a href='https://bit.ly/FFUDCP'>
							<figure>
								<img
									style={{ maxHeight: '60px' }}
									className='img-responsive'
									src={require('./images/TPS-_0000_FlairFinanceBTN.png')}
									alt='Flair Financew NFTs'
								/>
							</figure>
						</a>
					</div>
				</div>

				<div
					className='divTable'
					style={{
						alignContent: 'center',
						textAlign: 'center',
						margin: '0 auto',
						width: '100%',
						maxWidth: '600px',
					}}
				>
					<div className='divTableBody d-flex justify-content-center'>
						<div className='divTableRow' style={{width: '80%'}}>
							<div className='divTableCell card2 hover02' style={{padding: 0}}>
								<a
									style={styles.sixteen}
									href='https://www.tiktok.com/@twitchpixelstudios'
									target='_blank'
									rel='noreferrer'
								>
										<img
											alt='TikTok'
											src={require('./images/Social-Icons-_0004_TikTok.webp')}
											style={{width: '75%', height: 'auto'}}
										/>
								</a>
							</div>
							<div className='divTableCell card2 hover02' style={{padding: 0}}>
								<a
									style={styles.sixteen}
									href='https://www.instagram.com/twitchpixel'
									target='_blank'
									rel='noreferrer'
								>
										<img
											alt='Instagram'
											src={require('./images/Social-Icons-_0001_Instagram.webp')}
											style={{width: '75%', height: 'auto'}}
										/>
								</a>
							</div>
							<div className='divTableCell card2 hover02' style={{padding: 0}}>
								<a
									style={styles.sixteen}
									href='https://www.Youtube.com/twitchpixelstudios'
									target='_blank'
									rel='noreferrer'
								>
										<img
											alt='Youtube'
											src={require('./images/Social-Icons-_0005_Youtube.webp')}
											style={{width: '75%', height: 'auto'}}
										/>

								</a>
							</div>
							<div className='divTableCell card2 hover02' style={{padding: 0}}>
								<a
									style={styles.sixteen}
									href='https://www.facebook.com/TwitchPixel/'
									target='_blank'
									rel='noreferrer'
									>
										<img
											alt='Facebook'
											src={require('./images/Social-Icons-_0000_Facebook.webp')}
											style={{width: '75%', height: 'auto'}}
										/>
								</a>
							</div>
							<div className='divTableCell card2 hover02' style={{padding: 0}}>
								<a
									style={styles.sixteen}
									href='https://www.twitter.com/twitchpixelanim'
									target='_blank'
									rel='noreferrer'
								>
										<img
											alt='Twitter'
											src={require('./images/Social-Icons-_0002_Twitter.webp')}
											style={{width: '75%', height: 'auto'}}
										/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='col'>
				<div className='card4'>
					<img
						loading='lazy'
						className='img-responsive'
						style={{ maxHeight: '60px', display: 'block', margin: '0 auto', width: '100%' }}
						src={require('./images/OurstreamersHeader.png')}
						alt='Our Artists'
					/>
				</div>
			</div>

			<div
				className='container'
				style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}
			>
				<div className='row'>
					<div className='col-xs-6 col-md-4 col-lg-4'>
						<div className='card2 hover02'>
							<a
								href='http://instagram.com/@JSaamanen'
								target='_blank'
								rel='noreferrer'
							>
								<figure>
									<img
										style={{ maxHeight: '170px', width: '80%' }}
										loading='lazy'
										className='img-responsive'
										src={require('./images/TWPS-_0001_Jeff-Subhead.png')}
										alt='Jeff on Social Media'
									/>
								</figure>
							</a>
						</div>
					</div>
					<div className='col-xs-6 col-md-4 col-lg-4'>
						<div className='card2 hover02'>
							<a
								href='http://instagram.com/@Nerd_Natalie'
								target='_blank'
								rel='noreferrer'
							>
								<figure>
									<img
										style={{ maxHeight: '170px', width: '80%' }}
										loading='lazy'
										className='img-responsive'
										src={require('./images/TWPS-_0000_Natalie-Subhead.png')}
										alt='Natalie on Social Media'
									/>
								</figure>
							</a>
						</div>
					</div>
					<div className='col-xs-6 col-md-4 col-lg-4'>
						<div className='card2 hover02'>
							<a
								href='http://instagram.com/@GGStratto'
								target='_blank'
								rel='noreferrer'
							>
								<figure>
									<img
										style={{ maxHeight: '170px', width: '80%' }}
										loading='lazy'
										className='img-responsive'
										src={require('./images/TWPS-_0003_Mike-Subhead.png')}
										alt='Mike on Social Media'
									/>
								</figure>
							</a>
						</div>
					</div>
				</div>
			</div>

			<div
				className='text-center'
				style={{ margin: '0 auto', backgroundColor: '#1d0036', width: '80%' }}
			>
				<img
					alt='banner'
					src={require('./images/_0000_TPS-Banner-Logo.png')}
					style={{ maxHeight: '30px', marginTop: '30px' }}
				/>
				<div
					className='footer-copyright text-center py-3'
					style={{ color: 'white', fontSize: '14px' }}
				>
					©2021 Copyright Twitch Pixel Studios Inc.
					<br />
					All Intellectual Property Is Owned By Twitch Pixel Studios Inc.
				</div>
			</div>
		</div>
	);
};
