import React from 'react';
const styles = {
	hRule: {
		borderTop: '1px solid grey',
	},
};

export const Auth = () => {
	return (
		<div>
			<div className='container'>
			<h2 style={{ color: 'white', marginTop: '25px', textAlign: 'center' }}>This event is now over. </h2><p style={{ color: 'white', textAlign: 'center' }}>Please see your 'Omni-Pass Digital Downloads' below to download this event as a VOD! <br></br>To stay updated on all our Omnio-Pass Events follow <a href='https://twitter.com/TwitchPixelInc'>@TwitchPixelInc</a> &amp;  <a href='https://twitter.com/TwitchPixelAnim'>@TwitchPixelAnim</a> on Twitter</p>
			<img
										style={{width: '100%'}}
										loading='lazy'
										className='img-responsive'
										src={require('./images/_0001_TPS-OmniPassEventBanner-auth.png')}
										alt='Live Stream April 23rd, 2022'
									/>
				<div className='row mt-3 ytembedd'>
                <iframe
                src='https://player.restream.io/?token=6335052dd4074e2cb549a30aca7eaa2b'
                title='Voice Actor Round Table - Twitch Pixel Studios'
                width='100%'
                height='100%'
                allowFullScreen
                frameBorder='0'
                scrolling='no'
                allow='autoplay'
                ></iframe>
				</div>
				<div className='row mt-3 ytembeddnfo'>
					<div className='container'>
						<div
							className='container'
							style={{ color: 'white', marginTop: '25px' }}
						>
							<div className='row'>
								<div
									className='col-xs-12'
									style={{
										textAlign: 'center',
										maxWidth: '800px',
										marginTop: '25px',
										marginRight: 'auto',
										marginBottom: '25px',
										marginLeft: 'auto',
										display: 'block',
										clear: 'both',
										padding: '25px',
									}}
								>
									<h5>YOUR OMNI PASS ACCESS!</h5>
									<h3 style={{ marginTop: '-10px' }}>
										Exclusive content for our OmniPass Supporters!
									</h3>
									<p>
										Thank you for suporting Twitch Pixel Studios! Below give you
										access to your exclusive content! <br />
										Be sure to{' '}
										<strong>join our discord for even more benefits!</strong>
									</p>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-4'>
									<a href='https://discord.gg/U2w3n4wDHb'>
										<img
											src={require('./images/JoinTwitchPixelOnDiscord.png')}
											style={{
												width: '300px',
												margin: '0 auto',
												display: 'block',
												marginBottom: '15px',
											}}
											alt='mint citizen'
										/>
									</a>
									<ul
										style={{
											textAlign: 'left',
											listStyleType: 'none',
											backgroundColor: '#360044',
											marginLeft: '0px',
											marginRight: '2px',
											paddingTop: '20px',
											paddingBottom: '20px',
											paddingRight: '35px',
											fontSize: '12px',
										}}
									>
										<li
											style={{
												textAlign: 'center',
												fontWeight: 700,
												fontSize: '14px',
											}}
										>
											Claim your access by <br />
											<a href='https://discord.gg/U2w3n4wDHb'>
												<strong>joining our discord server</strong>
											</a>
											<br /> and requesting your new role from one of our mods!
										</li>
										<hr style={styles.hRule} />
										<li>ThetaMaster Exclusive Discord Role</li>
										<hr style={styles.hRule} />
										<li>
											Access To Voice Actor Live Chat! (Ask questions and
											hangout!)
										</li>
										<hr style={styles.hRule} />
										<li>
											Access to Twitch Pixel Studios behind the scenes channels
											for all our shows as we grow!
										</li>
										<hr style={styles.hRule} />
										<br />
										<a href='https://discord.gg/U2w3n4wDHb'>
											<button
												type='button'
												className='btn btn-primary'
												style={{
													padding: '15px',
													backgroundColor: 'mediumseagreen',
													border: 'none',
													width: '100%',
													textAlign: 'center',
												}}
											>
												<strong>JOIN NOW!</strong>
											</button>
										</a>
									</ul>
								</div>
								<div className='col-md-4'>
									<img
										src={require('./images/OmniPassDigitalDownloads.png')}
										style={{
											width: '300px',
											margin: '0 auto',
											display: 'block',
											marginBottom: '15px',
										}}
										alt='omnipass'
									/>
									<ul
										style={{
											textAlign: 'left',
											listStyleType: 'none',
											backgroundColor: '#360044',
											marginLeft: '2px',
											marginRight: '0px',
											paddingTop: '20px',
											paddingBottom: '20px',
											paddingRight: '35px',
											fontSize: '12px',
										}}
									>
										<li
											style={{
												textAlign: 'center',
												fontWeight: 700,
												fontSize: '18px',
												backgroundColor: 'purple',
												padding: '10px',
											}}
										>
											Digital Downloads
										</li>
										<hr style={styles.hRule} />
										<li
											style={{
												textAlign: 'center',
												fontWeight: 700,
												fontSize: '14px',
											}}
										>
											Get Your <br />
											Exclusive Digital Perks Below!
										</li>
										<hr style={styles.hRule} />
										<li>
											<a
												style={{ fontWeight: 700, color: 'springgreen' }}
												href='https://drive.google.com/file/d/1L9yGURzsNCvT0VVbXsglwpbQf2-_sKo_/view?usp=sharing'
											>
												DOWNLOAD Posters Pack!
											</a>
										</li>
										<hr style={styles.hRule} />
										<li>
											<a
												style={{ fontWeight: 700, color: 'springgreen' }}
												href='https://drive.google.com/file/d/1vymq0UHJsyHvwsvSnYBF6D4lq_oNCgdC/view?usp=sharing'
											>
												DOWNLOAD Digital Wallpapers Pack!
											</a>
										</li>
										<hr style={styles.hRule} />
										<li>
										<a
												style={{ fontWeight: 700, color: 'springgreen' }}
												href='https://drive.google.com/file/d/1xa_PRmMXxonJbj9hwdkmNV9CH3EWKFOH/view?usp=sharing'
											>
												DOWNLOAD Voice Actor Roundtable VOD!
											</a>
										</li>
										<hr style={styles.hRule} />
										<br />
									</ul>
								</div>
								<div className='col-md-4'>
									<a href='https://www.patreon.com/TwitchPixel'>
										<img
											src={require('./images/SupportTwitchPixelOnPatreon.png')}
											style={{
												width: '300px',
												margin: '0 auto',
												display: 'block',
												marginBottom: '15px',
											}}
											alt='Patreon'
										/>
									</a>
									<ul
										style={{
											textAlign: 'left',
											listStyleType: 'none',
											backgroundColor: '#360044',
											marginLeft: '0px',
											marginRight: '2px',
											paddingTop: '20px',
											paddingBottom: '20px',
											paddingRight: '35px',
											fontSize: '12px',
										}}
									>
										<li
											style={{
												textAlign: 'center',
												fontWeight: 700,
												fontSize: '18px',
												backgroundColor: 'purple',
												padding: '10px',
											}}
										>
											SUPPORT For a Cup of Coffee
										</li>
										<hr style={styles.hRule} />
										<li
											style={{
												textAlign: 'center',
												fontWeight: 700,
												fontSize: '14px',
											}}
										>
											$4.99 / Month
										</li>
										<hr style={styles.hRule} />
										<li>Specialized Patreon Supporter Discord Role!</li>
										<hr style={styles.hRule} />
										<li>Access to BTS Discord Channels &amp; Live Streams!</li>
										<hr style={styles.hRule} />
										<li>Developer Diary Video Updates!</li>
										<hr style={styles.hRule} />
										<li>Patreon Only Digtial Rewards!</li>
										<hr style={styles.hRule} />
										<br />
										<a href='https://www.patreon.com/TwitchPixel'>
											<button
												type='button'
												className='btn btn-primary'
												style={{
													padding: '15px',
													backgroundColor: 'mediumseagreen',
													border: 'none',
													width: '100%',
													textAlign: 'center',
												}}
											>
												<strong>SUPPORT NOW!</strong>
											</button>
										</a>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
